import { AbstractControl } from '@angular/forms';

export const PRIMARY_PHONE_VALIDATOR = (
  mobilePhoneCtrl: AbstractControl,
  mobileIsPrimaryCtrl: AbstractControl,
  workPhoneCtrl: AbstractControl,
  workIsPrimaryCtrl: AbstractControl,
  otherPhoneCtrl: AbstractControl,
  otherIsPrimaryCtrl: AbstractControl
) => {
  return (): { [key: string]: boolean } | null => {
    const mobilePhone = mobilePhoneCtrl.value as string;
    const mobilePrimary = mobileIsPrimaryCtrl.value as boolean | null;
    const workPhone = workPhoneCtrl.value as string;
    const workPrimary = workIsPrimaryCtrl.value as boolean | null;
    const otherPhone = otherPhoneCtrl.value as string;
    const otherPrimary = otherIsPrimaryCtrl.value as boolean | null;

    if (mobilePhone.length || workPhone.length || otherPhone.length) {
      if (!mobilePrimary && !workPrimary && !otherPrimary) {
        return { required: true };
      }
    }

    return null;
  };
};

export const emailPattern = (emailCtrl: AbstractControl) => {
  if (emailCtrl.value) {
    const emailPattern =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailPattern.test(emailCtrl.value)) {
      return null;
    }
    return { email: true };
  }
  return null;
}
