import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'cpc-info-message',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './info-message.component.html',
  styleUrls: ['./info-message.component.scss'],
})
export class InfoMessageComponent {
  @Input()
  public message = '';
}
