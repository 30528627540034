import { Component, inject } from '@angular/core';
import { HeaderService } from '@cpc-mpd/services';
import { MpdRoutes } from '@libs/cross-plat';
@Component({
  selector: 'mpd-unsubscribe',
  templateUrl: './mpd-unsubscribe.component.html',
  styleUrls: ['./mpd-unsubscribe.component.scss'],
})
export class MpdUnsubscribeComponent {
  headerService = inject(HeaderService);
  public resubscribe = `/${this.headerService.currentRegion}/${MpdRoutes.StayConnected}`;
}
