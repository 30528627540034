// Here are all properties used by environment variables. So we can
// take this constant and reuse to populate each especific environment.
// This approach allows us to have the same properties across multiple
// environments and also have the capability to change some other
// properties.

export const commomEnv = {
  production: false,
  minFellowship: -1,
  maxFellowship: 10,
  apiBase: '',
  goreApiBases: {},
  contactUrl: 'https://www.gore.com/privacy/contact',
  privacyUrl: 'https://www.gore.com/privacy',
  infoWidthRem: 18.4375,
  thankYouVideoUrl: 'https://youtu.be/rcIE3Gr1_Os',
  thankYouEmbedUrl: 'https://www.youtube.com/embed/rcIE3Gr1_Os',
  globalRegion: 'GLOBAL',
};
