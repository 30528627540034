import { Component, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

import { AdvancedFormControl } from '@libs/cross-plat/models';

@Component({ template: '' })
export abstract class ErrorComponent {
  @Input()
  public source!: AdvancedFormControl;

  public get isInvalid(): boolean {
    return (
      this.source.invalid &&
      (this.source.dirty || this.source.touched) &&
      !!this.source.errors &&
      this.getError(this.source.errors)
    );
  }

  protected abstract getError(errors: ValidationErrors): boolean;
}
