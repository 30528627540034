import { BehaviorSubject, Observable } from 'rxjs';
import { SelectableItem } from '@libs/cross-plat/models';

import { Injectable } from '@angular/core';

import { FacadePreferenceService } from './facade-preference.service';

@Injectable({
  providedIn: 'root',
})
export class ConsentService {
  private consent: BehaviorSubject<SelectableItem> | null = null;

  constructor(private readonly facadeService: FacadePreferenceService) {}

  public getConsent(): Observable<SelectableItem> {
    if (!this.consent) {
      this.consent = new BehaviorSubject<SelectableItem>({} as SelectableItem);
    } else {
      this.consent.next({} as SelectableItem);
    }

    if (!this.consent.value.id) {
      this.facadeService
        .getConsent()
        .subscribe((consent: SelectableItem) => this.consent?.next(consent));
    }

    return this.consent;
  }
}
