import { AbstractControl } from '@angular/forms';

export const POPULATED_ARRAY_VALIDATOR = (
  control: AbstractControl
): { [key: string]: boolean } | null => {
  if (!Array.isArray(control.value)) {
    return { notArray: true };
  }

  const arr = control.value as [];

  if (arr.length === 0) {
    return { empty: true };
  }

  return null;
};
