import { Component } from '@angular/core';
import { environment } from '@cpc-mpd/environment';

@Component({
  selector: 'mpd-professional-explain',
  templateUrl: './professional-explain.component.html',
  styleUrls: ['./professional-explain.component.scss'],
})
export class ProfessionalExplainComponent {
  public get privacyUrl(): string {
    return environment.privacyUrl;
  }
}
