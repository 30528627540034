import { BehaviorSubject, Observable } from 'rxjs';

import { HelperService } from '@libs/ui-cdk/services';

import { SelectableItem } from '@libs/cross-plat';

import { Injectable } from '@angular/core';

import { FacadePreferenceService } from '@libs/ui-cdk';
import { WorkFunction } from '../models/work-function';

@Injectable({
  providedIn: 'root',
})
export class FunctionService {
  private functions: BehaviorSubject<WorkFunction[]> | null = null;

  constructor(
    private readonly helperService: HelperService,
    private readonly facadeService: FacadePreferenceService
  ) {}

  public getWorkFunctions(): Observable<WorkFunction[]> {
    if (!this.functions) {
      this.functions = new BehaviorSubject<WorkFunction[]>([]);
    } else {
      this.functions.next([]);
    }

    this.facadeService
      .getDivisionPreferences()
      .subscribe((data: SelectableItem[]) => {
        this.helperService.sort(data);
        this.functions?.next(
          data.map((item: SelectableItem) => item as WorkFunction)
        );
      });

    return this.functions;
  }
}
