<div
  class="container title"
  fxLayout="row"
  fxLayoutGap="2%"
  fxLayoutAlign="left">
  <h2>Contact Information</h2>
  <span class="material-icons info" (click)="getExplanation()" #info>
    info_outline
  </span>
</div>

<div
  class="container"
  fxLayout="row"
  fxLayout.lt-md="column"
  fxLayoutGap="2%"
  fxLayoutAlign="left">
  <div fxFlex="17%" fxFlex.lt-sm="100%">
    <div class="group">
      <label for="titleSelect">Title:</label>
      <mat-form-field appearance="outline">
        <mat-select
          [formControl]="form.titleCtrl"
          placeholder="Select"
          id="titleSelect">
          <mat-option value="">Select</mat-option>
          <mat-option *ngFor="let title of titles" [value]="title">
            {{ title.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <cpc-error-collection [source]="form.titleCtrl"></cpc-error-collection>
    </div>
  </div>
  <div fxFlex="30%" fxFlex.lt-sm="100%">
    <div class="group">
      <label cpcStar for="firstName">First Name:</label>
      <mat-form-field appearance="outline">
        <input
          matInput
          placeholder="Enter First Name"
          [formControl]="form.firstNameCtrl"
          name="firstName"
          id="firstName" />
      </mat-form-field>
      <cpc-error-collection
        [source]="form.firstNameCtrl"></cpc-error-collection>
    </div>
  </div>
  <div fxFlex="50%" fxFlex.lt-sm="100%">
    <div class="group">
      <label cpcStar for="lastName">Last Name:</label>
      <mat-form-field appearance="outline">
        <input
          matInput
          placeholder="Enter Last Name"
          [formControl]="form.lastNameCtrl"
          name="lastName"
          id="lastName" />
      </mat-form-field>
      <cpc-error-collection [source]="form.lastNameCtrl">
      </cpc-error-collection>
    </div>
  </div>
</div>
<div
  class="container"
  fxLayout="row"
  fxLayout.lt-md="column"
  fxLayoutGap="2%"
  fxLayoutAlign="left">
  <div fxFlex="50%" fxFlex.lt-sm="100%">
    <div class="group">
      <label cpcStar for="email">Email:</label>
      <mat-form-field appearance="outline" class="input-icon-box">
        <input
          matInput
          placeholder="Enter Email Address"
          [formControl]="form.emailCtrl"
          name="email"
          id="email" />
        <span class="material-icons" *ngIf="emailIsValid">
          check_circle_outline
        </span>
      </mat-form-field>
      <cpc-error-collection [source]="form.emailCtrl"></cpc-error-collection>
    </div>
  </div>
  <div fxFlex="50%" fxFlex.lt-sm="100%"></div>
</div>
<div
  class="container"
  fxLayout="row"
  fxLayout.lt-md="column"
  fxLayoutGap="2%"
  fxLayoutAlign="left">
  <div fxFlex="50%" fxFlex.lt-sm="100%">
    <div class="group">
      <label cpcStar for="countrySelect" for="countryselect"
        >Country / Region:</label
      >
      <mat-form-field appearance="outline">
        <mat-select
          [formControl]="form.countryCtrl"
          placeholder="Select Country/Region"
          (selectionChange)="countryChange()"
          name="country"
          id="countryselect">
          <mat-option *ngFor="let country of countries" [value]="country">
            {{ country.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <cpc-error-collection [source]="form.countryCtrl"></cpc-error-collection>
    </div>
  </div>
  <div fxFlex="50%" fxFlex.lt-sm="100%">
    <div class="group" *ngIf="states.length > 0">
      <label cpcStar for="stateSelect">State / Province:</label>
      <mat-form-field appearance="outline">
        <mat-select
          [formControl]="form.stateCtrl"
          placeholder="Select State/Province"
          name="state"
          id="stateSelect">
          <mat-option *ngFor="let state of states" [value]="state">
            {{ state.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <cpc-error-collection [source]="form.stateCtrl"></cpc-error-collection>
    </div>
  </div>
</div>
