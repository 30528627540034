import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID, signal } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
// import { environment } from 'src/environments/environment';
import { HeaderFooterJson } from '@libs/cross-plat/models';
import { AuthTokenService } from './auth-token.service';
// import { HeaderService } from './header.service';

@Injectable({
  providedIn: 'root',
})
export class HeaderFooterService {
  public headerFooterJson = signal<HeaderFooterJson | undefined>(undefined);
  constructor(
    private readonly http: HttpClient,
    private authTokenService: AuthTokenService
  ) {}

  // public getHeaderFooter(): Observable<boolean> {
  public getHeaderFooter(url: string): Observable<boolean> {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.authTokenService.AUTH_TOKEN}`,
      }),
    };
    // return this.http.get<HeaderFooterJson>(this.getUrl(), HTTP_OPTIONS).pipe(
    return this.http.get<HeaderFooterJson>(url, HTTP_OPTIONS).pipe(
      tap(response => {
        this.headerFooterJson.set(response);
      }),
      map(() => true)
    );
  }
}
