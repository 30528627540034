import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IHttpHeader } from '@libs/cross-plat/models';

@Injectable({
  providedIn: 'root',
})
export class ApiHelperService {
  // These variables (local and apiBase) must be set in each project that is going to use this service.
  // They can be set in app.component.ts on each project
  public locale = '';
  public apiBase = '';

  public get headers(): IHttpHeader {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
  }

  public appendLocale<T>(data: T): T {
    return { ...{ locale: this.locale }, ...data };
  }

  public getUrl(dataName: string, includeLocale = false): string {
    let url = this.apiBase + dataName;

    if (includeLocale) {
      url += '?locale=' + this.locale;
    }

    return url;
  }
}
