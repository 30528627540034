import {
  ErrorCollectionComponent,
  ErrorSummaryComponent,
  InfoMessageComponent,
  SeparatorComponent,
  SuccessMessageComponent,
  UnsubscribeComponent,
  UnsubscribeSurveyComponent,
  UnsubscribeModalComponent,
  CheckboxGroupingComponent,
  LoaderComponent,
} from '@libs/ui-cdk/components';

export const SHARED_STANDALONE_COMPONENTS = [
  ErrorCollectionComponent,
  ErrorSummaryComponent,
  InfoMessageComponent,
  SeparatorComponent,
  SuccessMessageComponent,
  UnsubscribeComponent,
  UnsubscribeSurveyComponent,
  UnsubscribeModalComponent,
  CheckboxGroupingComponent,
  LoaderComponent,
];

export * from './separator/separator.component';
export * from './api-error/api-error.component';
export * from './error-collection/error-collection.component';
export * from './error-email/error-email.component';
export * from './error-max/error-max.component';
export * from './error-max-length/error-max-length.component';
export * from './error-min/error-min.component';
export * from './error-min-length/error-min-length.component';
export * from './error-phone-number/error-phone-number.component';
export * from './error-primary-phone/error-primary-phone.component';
export * from './error-required/error-required.component';
export * from './error-summary/error-summary.component';
export * from './info-message/info-message.component';
export * from './phone-number/phone-number.component';
export * from './success-message/success-message.component';
export * from './unsubscribe/unsubscribe.component';
export * from './unsubscribe-modal/unsubscribe-modal.component';
export * from './unsubscribe-survey/unsubscribe-survey.component';
export * from './checkbox-grouping/checkbox-grouping.component';
export * from './loader/loader.component';
