export class MpdRoutes {
  public static get ManagePreferences(): string {
    return 'manage-preferences';
  }

  public static get StayConnected(): string {
    return '';
  }

  public static get ThankYou(): string {
    return 'thank-you';
  }

  public static get Unsubscribed(): string {
    return 'unsubscribe';
  }

  public static get UnsubscribeSurvey(): string {
    return 'unsubscribe-survey';
  }
}

export const MPD_ROTERS_LIST = [
  'manage-preferences',
  'thank-you',
  'unsubscribe',
  'unsubscribe-survey',
];
