import { SubscriptionComponent } from '@libs/ui-cdk/components/base';

import { ComponentType } from '@angular/cdk/portal';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { commomEnv } from '../../../../../cross-plat/src/lib/constants';

@Component({ template: '' })
export abstract class HasInfoComponent<T> extends SubscriptionComponent {
  @ViewChild('info')
  public info!: ElementRef;

  protected abstract componentType: ComponentType<T>;

  constructor(private readonly dialog: MatDialog) {
    super();
  }

  // If we need to reposition this, we will use this code.
  // It will need to go into the ngOnInit.
  // For that, instead of getDialogById, just hold onto the reference when opening.
  // this.subs.push(
  //   fromEvent(window, 'resize').subscribe(() => {
  //     const elem = this.info.nativeElement as HTMLElement;
  //     const rect = elem.getBoundingClientRect();

  //     this.dialog.getDialogById('test')?.updatePosition({
  //       top: `${rect.top}px`,
  //       left: `${rect.right + 16}px`,
  //     });
  //   })
  // );

  public getExplanation(): void {
    const elem = this.info.nativeElement as HTMLElement;
    const rect = elem.getBoundingClientRect();

    const config = {
      position: {
        top: `25vh`,
        left: `${rect.right + 16}px`,
      },
      width: `${commomEnv.infoWidthRem}rem`,
    };

    this.dialog.open(this.componentType, config);
  }
}
