import { HeaderFooterService } from '@libs/cross-plat/helpers/header-footer.service';
import { HeaderFooterJson } from '@libs/cross-plat/models';
import { Component, OnInit, Renderer2 } from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'mpd-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  constructor(
    private renderer: Renderer2,
    private readonly sanitizer: DomSanitizer,
    private readonly headerFooterService: HeaderFooterService
  ) {}

  ngOnInit(): void {
    const allowedStyles = ['css_footer', 'css_header', 'css_icons_data_svg'];
    const jsonResponse =
      this.headerFooterService.headerFooterJson() as HeaderFooterJson;
    const cssStylesKeys = Object.keys(jsonResponse);
    cssStylesKeys.forEach((styleKey: string) => {
      if (allowedStyles.includes(styleKey)) {
        const sanitizedStyles = this.sanitizer.bypassSecurityTrustStyle(
          jsonResponse[styleKey as keyof HeaderFooterJson] as string
        ) as string;
        this.addStyleTagInDoc(sanitizedStyles, styleKey);
      }
    });

    const script = this.renderer.createElement('script') as HTMLScriptElement;
    script.text = jsonResponse.js_header_footer as string;
    this.renderer.appendChild(document.body, script);
  }

  private addStyleTagInDoc(styleText: string, styleName: string): void {
    const styles = this.renderer.createElement('style') as HTMLScriptElement;
    styles.type = 'text/css';
    styles.className = styleName;
    styles.appendChild(document.createTextNode(styleText));
    this.renderer.appendChild(document.head, styles);
  }
}
