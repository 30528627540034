<div class="center">
  <span i18n="@@Error" mat-dialog-title class="mat-dialog-title subtitle"
    >Error</span
  >
</div>
<div mat-dialog-content class="mat-dialog-content dialog-content">
  <p class="body" i18n="@@refreshAndTryAgain">
    There was an error loading data. Please refresh the page and try again.
  </p>
</div>
<div mat-dialog-actions class="mat-dialog-actions center">
  <button
    mat-dialog-close
    mat-flat-button
    type="button"
    color="primary"
    (click)="close()"
    i18n="@@OK">
    OK
  </button>
</div>
