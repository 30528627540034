import { CanActivateFn } from '@angular/router';
import { of, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthTokenService } from '@libs/cross-plat/helpers/auth-token.service';
import { inject } from '@angular/core';
import { HeaderFooterService } from '@libs/cross-plat/helpers/header-footer.service';

export function getAccessTokenGuard(apiUrl: string): CanActivateFn {
  return () => {
    const tokenService = inject(AuthTokenService);
    return tokenService.getAuthToken(apiUrl).pipe(
      catchError(() => {
        return of(false);
      })
    );
  };
}

export function getHeaderFooterResolver(apiUrl: string): Observable<boolean> {
  const headerFooterService = inject(HeaderFooterService);
  return headerFooterService.getHeaderFooter(apiUrl);
}
