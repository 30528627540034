/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ApiErrorComponent } from '@libs/ui-cdk/components';

const MAX_RETRIES = 2;

@Injectable({
  providedIn: 'root',
})
export class ErrorDialogService {
  private get openReferences(): MatDialogRef<any, any>[] {
    const dialogs = this.dialog.openDialogs || [];

    return dialogs;
  }

  constructor(private readonly dialog: MatDialog) {}

  public open(): void {
    if (this.openReferences.length > 0) {
      return;
    }

    this.dialog.open(ApiErrorComponent);
  }

  public retryOrOpen(retry: number, retryCB: () => void): number {
    if (retry < MAX_RETRIES) {
      retryCB();
    } else {
      this.open();
    }

    return retry + 1;
  }
}
