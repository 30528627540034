// Angular modules
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { RoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Material modules
import { MAT_COMMON_MODULES } from '@libs/cross-plat';
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialogConfig,
} from '@angular/material/dialog';

// Directives, components and pages
import { PAGES } from './pages';
import { COMPONENTS } from './components';
import { SHARED_DIRECTIVES, SHARED_STANDALONE_COMPONENTS } from '@libs/ui-cdk';

declare global {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  interface Window {
    adobeDataLayer: any[];
  }
}

@NgModule({
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule,
    RoutingModule,
    FlexLayoutModule,
    ...MAT_COMMON_MODULES,
    ...SHARED_DIRECTIVES,
    ...SHARED_STANDALONE_COMPONENTS,
  ],
  declarations: [AppComponent, ...PAGES, ...COMPONENTS],

  providers: [
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        ...new MatDialogConfig(),
        autoFocus: false,
        hasBackdrop: true,
        restoreFocus: false,
      } as MatDialogConfig,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
