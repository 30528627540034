<mpd-header></mpd-header>
<div
  class="container"
  fxLayout="row"
  fxLayoutWrap
  fxLayoutGap="2%"
  fxLayoutAlign="center">
  <div fxFlex="70%" fxFlex.xs="90%">
    <router-outlet></router-outlet>
  </div>
</div>
<mpd-footer></mpd-footer>
