import { Component } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { ErrorComponent } from '../base/error.component';

@Component({
  selector: 'cpc-error-min-length',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './error-min-length.component.html',
})
export class ErrorMinLengthComponent extends ErrorComponent {
  public get min(): number {
    return this.source.minLength;
  }

  protected getError(errors: ValidationErrors): boolean {
    return !!errors && !!errors.minlength;
  }
}
