import { Observable, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Event, Router, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RouterService {
  private evs = new ReplaySubject<Event>();

  public get events(): Observable<Event> {
    return this.evs;
  }

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute
  ) {
    router.events.pipe(tap((ev: Event) => this.evs.next(ev))).subscribe();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public route(urlParts: any[]): void {
    void this.router.navigate(urlParts, { relativeTo: this.activatedRoute });
  }
}
