import { Component } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { ErrorComponent } from '../base/error.component';

@Component({
  selector: 'cpc-error-min',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './error-min.component.html',
})
export class ErrorMinComponent extends ErrorComponent {
  public get min(): number {
    return this.source.min;
  }

  protected getError(errors: ValidationErrors): boolean {
    return !!errors?.min;
  }
}
