import { commomEnv } from '@libs/cross-plat/constants';

const goreApiDomain = 'https://goremed.dev.wlg-nonprod-site';

const env: Partial<typeof commomEnv> = {
  production: false,
  apiBase: 'https://api.preferencesvl.gore.com/mpd/',
  goreApiBases: {
    en: `${goreApiDomain}.com/api-content/`,
    ap: `${goreApiDomain}.com/ap/api-content/`,
    eu: `${goreApiDomain}.com/eu/api-content/`,
    jp: `${goreApiDomain}.com/jp/api-content/`,
    la: `${goreApiDomain}.com/la/api-content/`,
    me: `${goreApiDomain}.com/me/api-content/`,
  },
};

export const environment = { ...commomEnv, ...env };
