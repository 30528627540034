<div class="center heading">
  <span
    mat-dialog-title
    class="subtitle"
    i18n="@@unsubscribe-modal-unsubscribe">
    Unsubscribe from all Marketing Communications
  </span>
</div>
<div mat-dialog-content class="dialog-content">
  <p class="body" i18n="@@unsubscribe-modal-transactionalMessages">
    You will still receive important transactional messages concerning your
    account if you are a customer.
  </p>
  <p class="body">
    <a [href]="privacyUrl" i18n="@@unsubscribe-modal-href-privacyNotice"
      >Privacy Notice</a
    >
  </p>
</div>
<div mat-dialog-actions class="right">
  <button
    mat-dialog-close
    mat-flat-button
    type="button"
    color="primary"
    i18n="@@unsubscribe-modal-cancel-btn">
    Cancel
  </button>
  <button
    mat-dialog-close
    mat-stroked-button
    type="button"
    (click)="confirmClick()"
    i18n="@@unsubscribe-modal-Confirm-btn">
    Confirm
  </button>
</div>
