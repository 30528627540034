import { Subscription } from 'rxjs';

import { Component, OnDestroy } from '@angular/core';

@Component({ template: '' })
export abstract class SubscriptionComponent implements OnDestroy {
  private readonly subscriptions: Subscription[] = [];

  protected get subs(): Subscription[] {
    return this.subscriptions;
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((sub: Subscription) => {
      sub.unsubscribe();
    });
  }
}
