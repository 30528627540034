import { FormDataService, SalutationService } from '@cpc-mpd/services';
import { HasInfoComponent } from '@libs/ui-cdk/components/base';
import { Country, SelectableItem, Region } from '@libs/cross-plat';

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { LocationService } from '@libs/ui-cdk/services';

import { HeaderService } from '@cpc-mpd/services/header.service';

import { ContactExplainComponent } from '../contact-explain/contact-explain.component';

import { ɵparseCookieValue } from '@angular/common';

@Component({
  selector: 'mpd-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss'],
})
export class ContactInfoComponent
  extends HasInfoComponent<ContactExplainComponent>
  implements OnInit
{
  public countries: Country[] = [];
  public states: SelectableItem[] = [];
  public titles: SelectableItem[] = [];
  public region: Region | null = {
    id: 'NA',
    name: 'North America',
    data: 'americas',
    code: 'en',
    region: 'AMER',
    subregion: '',
  };

  public mpdInScopeCountries = [
    'AR',
    'AU',
    'AT',
    'BH',
    'BE',
    'BM',
    'BR',
    'CA',
    'CL',
    'CN',
    'CO',
    'CR',
    'HR',
    'CY',
    'CZ',
    'DK',
    'FI',
    'FR',
    'GF',
    'PF',
    'DE',
    'GR',
    'GL',
    'GP',
    'GU',
    'GG',
    'HU',
    'IS',
    'IN',
    'IE',
    'IL',
    'IT',
    'JP',
    'KW',
    'LB',
    'LU',
    'MQ',
    'YT',
    'MX',
    'MC',
    'NL',
    'NC',
    'NZ',
    'NO',
    'PA',
    'PL',
    'PT',
    'PR',
    'QA',
    'RE',
    'SM',
    'SA',
    'SG',
    'SI',
    'ZA',
    'KR',
    'ES',
    'SE',
    'CH',
    'TW',
    'TH',
    'TR',
    'AE',
    'GB',
    'US',
    'VI',
  ];

  protected componentType = ContactExplainComponent;

  public get emailIsValid(): boolean {
    const ctrl = this.form.emailCtrl;

    return (ctrl.dirty || ctrl.touched) && ctrl.valid;
  }

  constructor(
    public readonly form: FormDataService,
    private readonly locationService: LocationService,
    private readonly salutationService: SalutationService,
    private readonly headerService: HeaderService,
    dialog: MatDialog
  ) {
    super(dialog);
  }

  public ngOnInit(): void {
    this.subs.push(
      this.locationService.getLocations().subscribe((countries: Country[]) => {
        this.countries = countries;
        this.countries = this.countries.filter(o1 =>
          this.mpdInScopeCountries.some(
            o2 => o1.id.toLowerCase() === o2.toLowerCase()
          )
        );
        this.countries = this.setRegion(
          this.countries,
          this.headerService.currentRegion.toUpperCase()
        );
        const currentCountryId = this.getCountryFromCookie();
        if (currentCountryId) {
          const selected = this.countries.find(
            (c: Country) => currentCountryId === c.id
          );

          if (selected) {
            this.form.setCountry(selected);
            this.form.workFunctionCtrl.enable();
          }
        }
        this.countryChange();
      })
    );

    this.subs.push(
      this.salutationService
        .getSalutations()
        .subscribe((titles: SelectableItem[]) => (this.titles = titles))
    );

    this.subs.push(
      this.locationService.currentLocation.subscribe(
        (country: Country | null) => {
          if (country) {
            const selected = this.countries.find(
              (c: Country) => country.id === c.id
            );

            if (selected) {
              this.form.setCountry(selected);
            }
          }
        }
      )
    );
  }

  public countryChange(): void {
    const country = this.form.countryCtrl.value as Country;
    this.form.preferencesCtrl.setValue(null);
    this.form.workFunctionCtrl.setValue(null);
    this.form.areasOfInterestCtrl.setValue(null);
    this.form.specialtyCtrl.setValue(null);
    this.form.otherFunctionCtrl.setValue(null);
    this.form.otherSpecialtyCtrl.setValue(null);

    if (country.states) {
      this.states = country.states;
    } else {
      this.states = [];
    }

    this.form.setState(null);
  }

  /*
    getCountryFromCookie

    uses eParseCookie library to get current country from cookies.
    only functions properly if cookies are enabled by the user.
    returns country code as 2 letter string.

    @returns string
  */
  public getCountryFromCookie(): string | undefined {
    const optanonconsents = ɵparseCookieValue(
      document.cookie,
      'OptanonConsent'
    );
    const optanonconsent = optanonconsents
      ?.split('&')
      .find(item => item.includes('geolocation'));
    return optanonconsent?.substring(12, 14);
  }

  setRegion(countries: Country[], region: string): Country[] {
    region = region === 'EN' ? 'NA' : region;
    const regionCountry = countries.filter(
      (country: Country) => country.subregion === region
    );
    const otherCountry = countries.filter(
      (country: Country) => !regionCountry.includes(country)
    );
    return regionCountry.concat(otherCountry);
  }
}
