import { Component } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ErrorComponent } from '../base/error.component';

@Component({
  selector: 'cpc-error-required',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './error-required.component.html',
})
export class ErrorRequiredComponent extends ErrorComponent {
  protected getError(errors: ValidationErrors): boolean {
    return !!errors && !!errors.required;
  }
}
