import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { HeaderService } from '../services/header.service';
import { environment } from '@cpc-mpd/environment';
import { getHeaderFooterResolver } from '@libs/cross-plat/functions/guard-funtions';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

export const headerFooterResolver: ResolveFn<Observable<boolean>> = (
  route: ActivatedRouteSnapshot
) => {
  const headerService = inject(HeaderService);
  headerService.currentRegion = route.params.region as string;
  const apiUrl = `${
    environment.goreApiBases[headerService.currentRegion as keyof object]
  }header-footer-rest`;
  return getHeaderFooterResolver(apiUrl);
};
