import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import {
  AsYouType,
  isValidPhoneNumber,
  parsePhoneNumber,
} from 'libphonenumber-js';
import { SubscriptionComponent } from '@libs/ui-cdk/components/base';
import { ErrorCollectionComponent } from '@libs/ui-cdk/components';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { AdvancedFormControl } from '@libs/cross-plat/models';

@Component({
  selector: 'cpc-phone-number',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    ErrorCollectionComponent,
  ],
  templateUrl: './phone-number.component.html',
})
export class PhoneNumberComponent
  extends SubscriptionComponent
  implements OnInit
{
  @Input() id = '';
  @Input()
  public form!: AdvancedFormControl;
  @Input()
  public placeholder = '';
  @Input()
  public set countryId(value: string) {
    if (value) {
      this.country = value;
      this.maskPhoneNumber((this.form.value as string) || '');
    }
  }

  @Output()
  public numberChange = new EventEmitter<string>();

  @ViewChild('phoneNumber')
  public phoneNumber!: ElementRef;

  private country = 'US';

  public ngOnInit(): void {
    this.subs.push(
      this.form.valueChanges.subscribe((val: string) => {
        this.maskPhoneNumber(val);
        this.numberChange.emit(this.form.value as string);
      })
    );
  }

  private maskPhoneNumber(original: string): void {
    const regex = /\D/g;
    const countryCode = this.country || 'US';
    const input = this.phoneNumber.nativeElement as HTMLInputElement;

    // Before we start, get the current position
    const cursorPosition = input.selectionStart || 0;

    const start = original.substr(0, cursorPosition).replace(regex, '');

    const originalOnlyNum = original.replace(regex, '');

    const phoneNumber =
      originalOnlyNum.length > 1
        ? parsePhoneNumber(originalOnlyNum, countryCode as any)
        : { nationalNumber: originalOnlyNum };

    const maskedPhoneNumber = new AsYouType(countryCode as any).input(original);

    const value = isValidPhoneNumber(maskedPhoneNumber, countryCode as any)
      ? maskedPhoneNumber
      : phoneNumber.nationalNumber;
    input.value = value;
    this.form.setValue(value, { onlySelf: true, emitEvent: false });

    let position = 0;
    let index = 0;

    for (let i = 0; i < value.length; i++) {
      if (start[index] === value[i]) {
        index += 1;
      }

      position += 1;

      if (index === start.length) {
        i = value.length;
      }
    }

    input.selectionStart = input.selectionEnd = position;
  }
}
