export class PsdRoutes {
  public static get ManagePreferences(): string {
    return 'manage-preferences';
  }

  public static get StayConnected(): string {
    return '';
  }

  public static get ThankYou(): string {
    return 'thank-you';
  }

  public static get Unsubscribed(): string {
    return 'unsubscribe';
  }

  public static get UnsubscribeSurvey(): string {
    return 'unsubscribe-survey';
  }
}
