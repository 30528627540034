import { HasInfoComponent } from '@libs/ui-cdk/components/base';
import { SelectableItem } from '@libs/cross-plat';

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { LanguageService } from '@libs/ui-cdk';
import { FormDataService } from '../../services/form-data.service';
import { ProfessionalExplainComponent } from '../professional-explain/professional-explain.component';
import { environment } from '@cpc-mpd/environment';

@Component({
  selector: 'mpd-professional-info',
  templateUrl: './professional-info.component.html',
  styleUrls: ['./professional-info.component.scss'],
})
export class ProfessionalInfoComponent
  extends HasInfoComponent<ProfessionalExplainComponent>
  implements OnInit
{
  public languages: SelectableItem[] = [];
  public readonly years: number[] = [];

  public get showFellowshipCompletion(): boolean {
    return this.form.fellowCtrl.value as boolean;
  }

  protected componentType = ProfessionalExplainComponent;

  constructor(
    public readonly form: FormDataService,
    private readonly languageService: LanguageService,
    dialog: MatDialog
  ) {
    super(dialog);

    const currentYear = new Date().getFullYear();

    for (
      let year = currentYear + environment.minFellowship;
      year <= currentYear + environment.maxFellowship;
      year++
    ) {
      this.years.push(year);
    }
  }

  public ngOnInit(): void {
    this.subs.push(
      this.languageService
        .getLanguages()
        .subscribe(
          (languages: SelectableItem[]) => (this.languages = languages)
        )
    );
  }

  public onIsFellowChange(checked: boolean): void {
    if (!checked) {
      this.form.setFellowshipCompleted('');
    }
  }
}
