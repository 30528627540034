import { UntypedFormControl } from '@angular/forms';

import { ErrorTypes } from '.';

export class AdvancedFormControl extends UntypedFormControl {
  public errorTypes: ErrorTypes[] = [];
  public minLength = 0;
  public maxLength = 0;
  public min = 0;
  public max = 0;
}
