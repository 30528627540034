import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { QueryParams } from '@libs/cross-plat';

@Component({
  selector: 'cpc-unsubscribe-modal',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule],
  templateUrl: './unsubscribe-modal.component.html',
  styleUrls: [
    '../../../../../../libs/ui-cdk/src/lib/scss/components/info-dialog.scss',
    './unsubscribe-modal.component.scss',
  ],
})
export class UnsubscribeModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { queryParams: QueryParams; url: string; privacyUrl: string },
    private readonly router: Router
  ) {}

  public get privacyUrl(): string {
    return this.data.privacyUrl;
  }

  public confirmClick(): void {
    void this.router.navigate([this.data.url], {
      queryParams: this.data.queryParams,
    });
  }
}
