import { Component, inject } from '@angular/core';
import { ApiHelperService } from '@libs/ui-cdk';
import { environment } from '@cpc-mpd/environment';
import { HeaderFooterService } from '@libs/cross-plat/helpers/header-footer.service';

@Component({
  selector: 'mpd-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  headerFooterService = inject(HeaderFooterService);
  constructor(private readonly apiHelperService: ApiHelperService) {
    this.apiHelperService.apiBase = environment.apiBase;
    this.apiHelperService.locale = 'en_US';
  }
}
