<div
  class="message-window"
  fxLayout="row"
  fxLayoutGap="2%"
  fxLayoutAlign="left">
  <div fxFlex="5%" fxFlex.lt-sm="10%">
    <div class="vertical-wrapper">
      <span class="material-icons">warning</span>
    </div>
  </div>
  <div class="body message-box" fxFlex="95%" fxFlex.lt-sm="90%">
    <span i18n="@@warning_message" class="message">{{ message }}</span>
  </div>
</div>
