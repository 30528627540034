import { Component } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { ErrorComponent } from '../base/error.component';

@Component({
  selector: 'cpc-error-max',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './error-max.component.html',
})
export class ErrorMaxComponent extends ErrorComponent {
  public get max(): number {
    return this.source.max;
  }

  protected getError(errors: ValidationErrors): boolean {
    return !!errors && !!errors.max;
  }
}
