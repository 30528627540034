import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
// import { environment } from 'src/environments/environment';
import { AuthTokenRes } from '@libs/cross-plat/models';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class AuthTokenService {
  public AUTH_TOKEN = '';

  constructor(private readonly http: HttpClient) {}

  public getAuthToken(apiUrl: string): Observable<boolean> {
    return this.http.get<AuthTokenRes>(apiUrl).pipe(
      tap(response => {
        this.AUTH_TOKEN = response.access_token;
      }),
      map(() => true)
    );
  }
}
