<div class="header center">
  <span mat-dialog-title class="subtitle"> Change Function/Specialty </span>
</div>
<div mat-dialog-content class="dialog-content">
  <p class="body">
    Your current preferences are showing areas of interest related to
    {{ current }}. If you decide to change function or specialty, you might need
    to select preferred areas of interest for the new function/specialty
    selected.
  </p>
  <p class="body">Are you sure you want to continue?</p>
</div>
<div mat-dialog-actions class="right">
  <button mat-flat-button type="button" color="primary" (click)="cancelClick()">
    Cancel
  </button>
  <button mat-stroked-button type="button" (click)="confirmClick()">
    Confirm
  </button>
</div>
