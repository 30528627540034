import { Injectable } from '@angular/core';
// import { Locale } from '../gore-branding/models/locale';

import { SelectableItem } from '@libs/cross-plat/models';

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
@Injectable({
  providedIn: 'root',
})
export class HelperService {
  /**
   * A method to recursively sort an array of objects and any properties it
   * contains that are also sortable.
   *
   * @param items A collection of items that implement/extend the Selectable Item interface.
   * @returns The sorted array to continue chain operations on.
   */
  // public sort<TSelectable extends SelectableItem | Locale>(
  public sort<TSelectable extends SelectableItem | any>(
    items: TSelectable[]
  ): TSelectable[] {
    // const sorted = items.sort((a: SelectableItem  | Locale, b: SelectableItem  | Locale) => {

    const sorted = items.sort(
      (a: SelectableItem | any, b: SelectableItem | any) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }
    );

    // sorted.forEach((item: SelectableItem  | Locale) => {
    sorted.forEach((item: SelectableItem | any) => {
      Object.keys(item).forEach((key: string) => {
        if (Object.prototype.hasOwnProperty.call(item, key)) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const prop: SelectableItem[] = (item as any)[key];

          if (
            !!prop &&
            Array.isArray(prop) &&
            prop.length > 0 &&
            Object.prototype.hasOwnProperty.call(prop[0], 'name')
          ) {
            (item as any)[key] = this.sort(prop);
          }
        }
      });
    });

    return sorted;
  }
}
