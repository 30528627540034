<!-- ---------------- -->
<div
  class="message-window"
  fxLayout="row"
  fxLayoutGap="2%"
  fxLayoutAlign="left"
  *ngIf="latestConsentVersion">
  <div fxFlex="5%" fxFlex.lt-sm="10%">
    <div class="vertical-wrapper">
      <div class="vertical-container">
        <span class="material-icons info">info_outline</span>
      </div>
    </div>
  </div>
  <div class="body message-box" fxFlex="95%" fxFlex.lt-sm="90%">
    <span class="message"
      >To ensure we have the most up-to-date permissions related to your
      preferences, please confirm your selections and press update
      preferences.</span
    >
  </div>
</div>

<!-- ------------ -->

<div
  class="container"
  [ngClass]="{ 'margin-top-style-container': latestConsentVersion === true }"
  fxLayout="row"
  fxLayoutAlign="left">
  <div fxFlex="100%">
    <h1>Manage Preferences</h1>
  </div>
</div>

<div class="container" fxLayout="row" fxLayoutAlign="left">
  <div fxFlex="100%">
    <p>Hi {{ formDataService.firstNameCtrl.value }},</p>
    <p>
      The marketing communications we send to you can be tailored at any time.
      Please update your preferences below.
    </p>
  </div>
</div>

<form>
  <mpd-function-selector
    [countryId]="countryId"
    [functionId]="functionId"
    [isReturn]="true"
    [selectedAreas]="preselectedAreas"
    [specialtyId]="specialtyId"></mpd-function-selector>
  <div *ngIf="areasOfInterest.length > 0">
    <p>
      The following areas of interest have been pre-selected based on the
      specialty you have selected. You can deselect from receiving
      communications by individually un-checking the box next to each area of
      interest.
    </p>
    <cpc-checkbox-grouping
      [form]="formDataService.areasOfInterestCtrl"
      [data]="areasOfInterest"
      (selectionsChanged)="changedAreasOfInterest()"
      title="Select Areas of Interest:"
      errorMessage="Please select at least one Area of Interest."></cpc-checkbox-grouping>
  </div>
  <cpc-separator></cpc-separator>
  <cpc-checkbox-grouping
    [form]="formDataService.preferencesCtrl"
    [data]="preferences"
    (selectionsChanged)="changedPreferences()"
    title="Select Marketing Preferences:"
    errorMessage="Please select at least one Marketing Preference Topic."></cpc-checkbox-grouping>
  <cpc-separator></cpc-separator>
  <p class="small-body">
    {{ consentText }}
  </p>

  <div
    class="container submit-row"
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutGap="2%"
    fxLayoutAlign="left">
    <div fxFlex="85%" fxFlex.lt-md="100%">
      <div
        class="vertical-wrapper wide-only unsubscribe"
        *ngIf="showUnsubscribe">
        <a href="javascript:;" (click)="showModal()">
          Unsubscribe from all Marketing Communications
        </a>
      </div>
      <cpc-error-summary
        *ngIf="showError"
        message="You are missing at least one required field, please review your contact and professional information and try again."></cpc-error-summary>
      <cpc-success-message
        *ngIf="successful"
        message="Preferences have been successfully updated."></cpc-success-message>
      <cpc-error-summary
        *ngIf="unsuccessful"
        message="Unable to update your settings. Please try again later."></cpc-error-summary>
    </div>
    <div fxFlex="15%" fxFlex.lt-md="100%">
      <div class="vertical-wrapper wide-only">
        <div class="right">
          <button
            mat-flat-button
            type="button"
            color="primary"
            (click)="submit()">
            Update Preferences
          </button>
        </div>
      </div>
    </div>
  </div>

  <label class="small-body bold privacy-title"> Your Privacy Rights </label>
  <p class="small-body">
    You have several rights related to the personal information we process about
    you. To understand and exercise these rights,
    <!--
      Ensure you keep the start of the closing anchor tags on the same line as the text.
      Otherwise, you get an extra space within the tag.
    -->
    <a [href]="contactUsUrl" target="_blank" rel="noopener noreferrer">
      click here</a
    >. Please refer to the
    <a [href]="privacyUrl" target="_blank" rel="noopener noreferrer">
      Privacy Notice</a
    >
    for more information.
  </p>
</form>
