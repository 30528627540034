<h2 i18n="@@unsubscribe-heading">We're sorry to see you go!</h2>
<p class="instructions">
  <ng-content select="span"></ng-content>
</p>
<form [formGroup]="form" (ngSubmit)="onUnsubscribe()">
  <h3 i18n="@@primaryReasonForUnsubscribe" class="question">
    What is your primary reason for unsubscribing?
  </h3>
  <div class="container" fxLayout="row" fxLayoutAlign="left">
    <div fxFlex="100%">
      <mat-radio-group
        class="unsubscribe-group"
        formControlName="unsubscribe"
        (change)="onRadioChange()"
        color="primary">
        <mat-radio-button
          class="radio-button"
          *ngFor="let reason of reasons"
          [value]="reason"
          disableRipple="true">
          {{ reason.name }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div
    *ngIf="showOtherReason"
    class="container reason-container"
    fxLayout="row"
    fxLayoutAlign="left">
    <div fxFlex="50%" fxFlex.lt-md="75%" fxFlex.lt-sm="100%">
      <div class="group">
        <mat-form-field appearance="outline">
          <textarea
            matInput
            class="other-reason"
            i18n-placeholder="@@reason_placeholder"
            placeholder="Describe a reason"
            formControlName="otherReason"></textarea>
        </mat-form-field>
        <cpc-error-collection [source]="otherReasonCtrl"></cpc-error-collection>
      </div>
    </div>
  </div>
  <div class="container" fxLayout="row" fxLayoutAlign="left">
    <button
      class="cancel-btn"
      mat-stroked-button
      type="button"
      (click)="onCancel()"
      i18n="@@unsubscribe_cancel_btn">
      Cancel
    </button>
    <button
      class="unsub-btn"
      mat-flat-button
      type="button"
      color="primary"
      [disabled]="!form.valid"
      i18n="@@unsubscribe_ok_btn"
      type="submit">
      Unsubscribe
    </button>
  </div>
  <div *ngIf="saveFailed" class="container" fxLayout="row" fxLayoutAlign="left">
    <cpc-error-summary
      message="Unable to unsubscribe. Please try again."></cpc-error-summary>
  </div>
  <div
    *ngIf="reasonNotFound"
    class="container"
    fxLayout="row"
    fxLayoutAlign="left">
    <cpc-error-summary
      message="There was an issue trying to save. Please try again."></cpc-error-summary>
  </div>
</form>
