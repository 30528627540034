import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MAT_CHECKBOX_DEFAULT_OPTIONS,
  MatCheckboxDefaultOptions,
  MatCheckboxModule,
} from '@angular/material/checkbox';
import { ErrorSummaryComponent } from '../error-summary/error-summary.component';
import {
  AdvancedFormControl,
  ErrorTypes,
  SelectableItem,
} from '@libs/cross-plat';
import { FlexLayoutModule } from '@angular/flex-layout';
import { StarDirective } from '@libs/ui-cdk/directives';

@Component({
  selector: 'cpc-checkbox-grouping',
  templateUrl: './checkbox-grouping.component.html',
  styleUrls: ['./checkbox-grouping.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatCheckboxModule,
    ErrorSummaryComponent,
    FlexLayoutModule,
    StarDirective,
  ],
  providers: [
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: {
        clickAction: 'noop',
      } as MatCheckboxDefaultOptions,
    },
  ],
})
export class CheckboxGroupingComponent implements OnInit {
  @Input()
  public errorMessage = '';
  @Input()
  public errorMessageCategory = '';
  @Input()
  public form!: AdvancedFormControl;
  @Input()
  public title = '';

  @Input()
  public disabled = false;

  @Input()
  public set data(value: SelectableItem[]) {
    this.items = value || [];
    this.updateForm();

    if (value.length) {
      this.loaded = true;
    }
  }

  @Output()
  public selectionsChanged = new EventEmitter<void>();

  public items: SelectableItem[] = [];

  private loaded = false;

  public get hasAny(): boolean {
    return this.items.length > 0;
  }

  public get hasAll(): boolean {
    return this.items.every((item: SelectableItem) => !!item.selected);
  }

  public get isRequired(): boolean {
    return this.form.errorTypes.indexOf(ErrorTypes.Required) > -1;
  }

  public get isInvalid(): boolean {
    return (
      !!this.form.invalid &&
      (this.form.dirty || this.form.touched) &&
      this.form.errors != null &&
      this.form.errors.empty
    );
  }

  public get isCategoryInvalid(): boolean {
    return (
      !!this.form.invalid &&
      (this.form.dirty || this.form.touched) &&
      this.form.errors != null &&
      this.form.errors.categoryPerIndustry
    );
  }

  public ngOnInit(): void {
    this.updateForm();
  }

  public deselectAll(): void {
    this.setAll(false);
  }

  public selectAll(): void {
    this.setAll(true);
  }

  public toggleItem(item: SelectableItem): void {
    item.selected = !item.selected;

    this.updateForm();
  }

  private setAll(val: boolean): void {
    this.items.forEach((item: SelectableItem) => (item.selected = val));

    this.updateForm();
  }

  private updateForm(): void {
    this.form.setValue(
      this.items.filter((item: SelectableItem) => item.selected)
    );

    this.selectionsChanged.emit();
  }
}
