import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  constructor(private readonly title: Title) {}

  /**
   * A function to set the page's browser title.
   * Will append common suffixes to what is provided.
   *
   * @param title The individual page title.
   */
  public setTitle(title: string): void {
    this.title.setTitle(`${title} | Gore`);
  }
}
