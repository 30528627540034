import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Country, SelectableItem } from '@libs/cross-plat';
import { LocationService } from '@libs/ui-cdk';
import { SubscriptionComponent } from '@libs/ui-cdk/components/base';

import { Specialty, WorkFunction } from '../../models';

import { FormDataService, FunctionService } from '../../services';
import { ChangeFunctionComponent } from '../change-function/change-function.component';
import { environment } from '@cpc-mpd/environment';

@Component({
  selector: 'mpd-function-selector',
  templateUrl: './function-selector.component.html',
  styleUrls: ['./function-selector.component.scss'],
})
export class FunctionSelectorComponent
  extends SubscriptionComponent
  implements OnInit
{
  @Input()
  public isReturn = false;

  @Input()
  public set countryId(value: string) {
    this.loadedCountryId = value;
  }

  @Input()
  public set functionId(value: string) {
    this.loadedFunctionId = value;

    if (value) {
      this.setSelectedFunctions();
    }
  }

  @Input()
  public set selectedAreas(value: SelectableItem[]) {
    this.loadedAreas = value || [];

    this.setSelectedFunctions();
  }

  @Input()
  public set specialtyId(value: string) {
    this.loadedSpecialtyId = value;

    if (value) {
      this.setSelectedFunctions();
    }
  }

  public validFunctions: WorkFunction[] = [];

  private countries: Country[] = [];
  private allFunctions: WorkFunction[] = [];
  private currentFunction!: WorkFunction | null;
  private currentSpecialty!: Specialty | null;
  private loadedAreas: SelectableItem[] = [];
  private loadedCountryId = '';
  private loadedFunctionId = '';
  private loadedSpecialtyId = '';

  private get currentCountry(): Country | undefined {
    if (this.isReturn) {
      return this.countries.find((c: Country) => this.loadedCountryId === c.id);
    } else if (this.formDataService.countryCtrl.value) {
      return this.formDataService.countryCtrl.value as Country;
    } else {
      return undefined;
    }
  }

  public get specialties(): Specialty[] {
    const func = this.formDataService.workFunctionCtrl.value as WorkFunction;

    // Some Functions may not have Specialties
    const specialties = func?.specialties ?? [];

    if (!this.currentCountry) {
      return specialties;
    } else {
      const country = this.currentCountry;

      const specialitiesFiltered = specialties.filter(
        spec =>
          spec.regions?.includes(environment.globalRegion) ||
          spec.regions?.includes(country.region) ||
          spec.regions?.includes(country.subregion)
      );

      if (!specialitiesFiltered.length) {
        this.formDataService.specialtyCtrl.clearValidators();
      }

      return specialitiesFiltered;
    }
  }

  public get showOtherFunction(): boolean {
    const func = this.formDataService.workFunctionCtrl.value as WorkFunction;

    return func ? func.showTextBox : false;
  }

  public get showOtherSpecialty(): boolean {
    const specialty = this.formDataService.specialtyCtrl.value as Specialty;

    return specialty ? specialty.showTextBox : false;
  }

  constructor(
    public readonly formDataService: FormDataService,
    private readonly functionService: FunctionService,
    private readonly locationService: LocationService,
    private readonly dialog: MatDialog
  ) {
    super();
  }

  public ngOnInit(): void {
    this.subs.push(
      this.locationService
        .getLocations()
        .subscribe((countries: Country[]) => (this.countries = countries))
    );

    this.subs.push(
      this.functionService
        .getWorkFunctions()
        .subscribe((functions: WorkFunction[]) => {
          this.allFunctions = functions;
          this.setSelectedFunctions();
        })
    );

    this.subs.push(
      this.formDataService.countryCtrl.valueChanges.subscribe(
        (country: Country) => {
          this.setValidFunctions(country);
        }
      )
    );
  }

  public functionChanged(func: WorkFunction): void {
    const origFunc = this.formDataService.otherFunctionCtrl.value as string;

    if (!func.showTextBox && origFunc !== '') {
      this.formDataService.setOtherFunction('');
    }

    const process = () => {
      this.formDataService.setSpecialty();

      // CREATE AN ARRAY TO GET THE NEW AREAS OF INTEREST THAT ARE PRE-SELECTED
      const selectedAoi: SelectableItem[] = [];

      if (func.areasOfInterest) {
        func.areasOfInterest.forEach(aoi => {
          if (aoi.preselect) {
            aoi.selected = true;
            selectedAoi.push(aoi); // ADD THE SELECTED AREA OF INTEREST TO THE ARRAY
          }
        });

        // SET THE AREAS OF INTEREST ON THE PARENT
        this.formDataService.setAreasOfInterest(selectedAoi);
      }

      // Make sure to update the Other Function and Specialty after the Function and Specialty are set.
      this.formDataService.otherFunctionCtrl.updateValueAndValidity();
      this.formDataService.otherSpecialtyCtrl.updateValueAndValidity();
      this.formDataService.formGroup.updateValueAndValidity();

      // Only do this the first time!
      this.currentFunction = null;
      this.currentSpecialty = null;
    };

    if (this.isReturn && this.currentFunction) {
      const dialogRef = this.dialog.open(ChangeFunctionComponent, {
        maxWidth: '700px',
        data: {
          current: this.currentFunction.name,
        },
      });

      dialogRef.afterClosed().subscribe((accepted: boolean) => {
        if (accepted) {
          process();
        } else {
          if (origFunc.trim().length > 0) {
            this.formDataService.setOtherFunction(origFunc);
          }

          this.formDataService.workFunctionCtrl.reset(this.currentFunction);
        }
      });
    } else {
      process();
    }
  }

  public specialtyChanged(specialty: Specialty): void {
    const origOther = this.formDataService.otherSpecialtyCtrl.value as string;

    if (!specialty.showTextBox && origOther !== '') {
      this.formDataService.setOtherSpecialty('');
    }

    if (specialty.areasOfInterest) {
      specialty.areasOfInterest.forEach(
        aoi => (aoi.selected = !!aoi.preselect)
      );
    }

    if (this.isReturn && this.currentSpecialty) {
      const dialogRef = this.dialog.open(ChangeFunctionComponent, {
        maxWidth: '700px',
        data: {
          current: this.currentSpecialty.name,
        },
      });

      dialogRef.afterClosed().subscribe((accepted: boolean) => {
        if (accepted) {
          this.currentSpecialty = null;
        } else {
          if (origOther.trim().length > 0) {
            this.formDataService.setOtherSpecialty(origOther);
          }

          this.formDataService.specialtyCtrl.reset(this.currentSpecialty);
        }
      });
    } else {
      this.currentSpecialty = null;
    }
  }

  private setValidFunctions(country: Country | undefined): void {
    if (!country) {
      this.validFunctions = this.allFunctions;
    } else {
      this.validFunctions = this.allFunctions.filter(
        func =>
          func.regions?.includes(environment.globalRegion) ||
          func.regions?.includes(country.region) ||
          func.regions?.includes(country.subregion)
      );
    }
  }

  private setSelectedFunctions(): void {
    const country = this.countries.find(
      (c: Country) => this.currentCountry?.id === c.id
    );

    this.setValidFunctions(country);

    const func = this.validFunctions.find(
      (workFunc: WorkFunction) => workFunc.id === this.loadedFunctionId
    );

    if (func) {
      const areaIds = this.loadedAreas.map((area: SelectableItem) => area.id);

      func.selected = true;
      this.currentFunction = func;
      this.formDataService.setFunction(func);

      if (func.areasOfInterest) {
        func.areasOfInterest.forEach((area: SelectableItem) => {
          if (areaIds.indexOf(area.id) > -1) {
            area.selected = true;
          }
        });
      }

      if (func.specialties) {
        const specialty = func.specialties.find(
          (spec: Specialty) => spec.id === this.loadedSpecialtyId
        );

        if (specialty) {
          specialty.selected = true;
          this.currentSpecialty = specialty;
          this.formDataService.setSpecialty(specialty);

          specialty.areasOfInterest.forEach((area: SelectableItem) => {
            if (areaIds.indexOf(area.id) > -1) {
              area.selected = true;
            }
          });
        }
      }
    }
  }
}
