import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AdvancedFormControl,
  ErrorTypes,
  PsdRoutes,
  SelectableItem,
} from '@libs/cross-plat/models';
import {
  ErrorCollectionComponent,
  ErrorSummaryComponent,
} from '@libs/ui-cdk/components';
import { MainPageComponent } from '@libs/ui-cdk/components/base';
import {
  RouterService,
  TitleService,
  UnsubscribeService,
} from '@libs/ui-cdk/services';

@Component({
  selector: 'cpc-unsubscribe-survey',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    ErrorSummaryComponent,
    ErrorCollectionComponent,
    MatRadioModule,
    MatButtonModule,
  ],
  templateUrl: './unsubscribe-survey.component.html',
  styleUrls: ['./unsubscribe-survey.component.scss'],
})
export class UnsubscribeSurveyComponent
  extends MainPageComponent
  implements OnInit
{
  @Input() businessName = '';
  @Input() nextUrl = '';
  @Input() homeUrl = '';

  public form!: UntypedFormGroup;
  public otherReasonCtrl!: AdvancedFormControl;
  public reasonNotFound = false;
  public reasons: SelectableItem[] = [];
  public saveFailed = false;
  public unsubscribeCtrl!: UntypedFormControl;

  public get canUnsubscribe(): boolean {
    const choice = this.unsubscribeCtrl.value as SelectableItem;

    return (
      (this.form.valid && !choice.showTextBox) || !!this.otherReasonCtrl.value
    );
  }

  public get showOtherReason(): boolean {
    const val = this.unsubscribeCtrl.value as SelectableItem;

    return val ? val.showTextBox : false;
  }

  private get otherReasonMax(): number {
    return 128;
  }

  constructor(
    private readonly unsubscribeService: UnsubscribeService,
    private readonly router: Router,
    titleService: TitleService,
    routerService: RouterService,
    activatedRoute: ActivatedRoute
  ) {
    super(titleService, routerService, activatedRoute);
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    const exists = super.redirectIfNoFederatedId(this.homeUrl);

    if (!exists) {
      return;
    }

    this.unsubscribeCtrl = new UntypedFormControl('', [Validators.required]);
    this.otherReasonCtrl = new AdvancedFormControl('', [
      Validators.maxLength(this.otherReasonMax),
    ]);
    this.otherReasonCtrl.maxLength = this.otherReasonMax;
    this.otherReasonCtrl.errorTypes = [
      ErrorTypes.Required,
      ErrorTypes.MaxLength,
    ];
    this.form = new UntypedFormGroup({
      unsubscribe: this.unsubscribeCtrl,
      otherReason: this.otherReasonCtrl,
    });

    this.subs.push(
      this.unsubscribeService
        .getUnsubscribeReasons()
        .subscribe((reasons: SelectableItem[]) => (this.reasons = reasons))
    );
  }

  public onCancel(): void {
    void this.router.navigate([PsdRoutes.ManagePreferences]);
  }

  public onRadioChange(): void {
    const val = this.unsubscribeCtrl.value as SelectableItem;
    if (!val.showTextBox) {
      this.form.get('otherReason')?.setValue('');
      this.form.get('otherReason')?.clearValidators();
      this.form.get('otherReason')?.markAsUntouched();
      this.form.get('otherReason')?.markAsPristine();
    } else {
      this.form
        .get('otherReason')
        ?.setValidators([
          Validators.required,
          Validators.maxLength(this.otherReasonMax),
        ]);
    }
    this.form.get('otherReason')?.updateValueAndValidity();
  }

  public onUnsubscribe(): void {
    this.reasonNotFound = false;
    if (this.form.valid) {
      const selected = this.unsubscribeCtrl.value as SelectableItem;
      const reason: SelectableItem | undefined = this.reasons.find(
        (r: SelectableItem) => r.id === selected.id
      );

      if (reason) {
        // let data: UnsubscribeResponse = {
        let data: any = {
          reason: reason.showTextBox
            ? (this.otherReasonCtrl.value as string)
            : reason.name,
          federatedId: this.federatedId,
        };

        data = { ...this.queryParams, ...data };

        this.subs.push(
          this.unsubscribeService.submit(data).subscribe(
            () =>
              this.router.navigate([this.nextUrl], {
                relativeTo: this.activatedRoute,
              }),
            () => (this.saveFailed = true)
          )
        );
      } else {
        this.reasonNotFound = true;
      }
    }
  }
}
