import { Injectable } from '@angular/core';
import { SelectableItem } from '@libs/cross-plat/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { FacadePreferenceService } from './facade-preference.service';

@Injectable({
  providedIn: 'root',
})
export class MarketingPreferencesService {
  private preferences: BehaviorSubject<SelectableItem[]> | null = null;

  constructor(private readonly facadeService: FacadePreferenceService) {}

  public getPreferences(): Observable<SelectableItem[]> {
    if (!this.preferences) {
      this.preferences = new BehaviorSubject<SelectableItem[]>([]);
    } else {
      this.preferences.next([]);
    }

    if (this.preferences.value.length === 0) {
      this.facadeService
        .getMarketingPreferences()
        .subscribe((preferences: SelectableItem[]) => {
          this.preferences?.next(preferences || []);
        });
    }

    return this.preferences;
  }
}
