<mat-form-field appearance="outline">
  <input
    matInput
    type="tel"
    i18n-placeholder="@@input_telephone_placeholder"
    [placeholder]="placeholder"
    [formControl]="form"
    #phoneNumber
    [id]="id" />
</mat-form-field>
<cpc-error-collection [source]="form"></cpc-error-collection>
