import { BehaviorSubject, Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Country, GetLocations, Region } from '@libs/cross-plat/models';
import { ApiHelperService } from './api-helper.service';
import { ErrorDialogService } from './error-dialog.service';
import { HelperService } from '@libs/ui-cdk/services';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  private current = new BehaviorSubject<Country | null>(null);
  private locations: BehaviorSubject<Country[]> | null = null;
  private retries = 0;
  public selectedRegion = new BehaviorSubject<Region | null>(null);

  constructor(
    private readonly helperService: HelperService,
    private readonly apiHelper: ApiHelperService,
    private readonly http: HttpClient,
    private readonly errorService: ErrorDialogService
  ) {}

  public get currentLocation(): Observable<Country | null> {
    return this.current;
  }

  public getLocations(): Observable<Country[]> {
    if (!this.locations) {
      this.locations = new BehaviorSubject<Country[]>([]);
    } else {
      this.locations.next([]);
    }

    this.http
      .get<GetLocations>(this.apiHelper.getUrl('locations', true))
      .subscribe(
        (data: GetLocations) => {
          const locations = this.helperService.sort(data.locations || []);

          if (!!data && locations.length > 0) {
            this.locations?.next(locations);
          } else {
            this.retries = this.errorService.retryOrOpen(this.retries, () =>
              this.getLocations()
            );
          }
        },
        () => {
          this.retries = this.errorService.retryOrOpen(this.retries, () =>
            this.getLocations()
          );
        }
      );

    return this.locations;
  }
}
