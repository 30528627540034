import { Component, inject } from '@angular/core';
import { HeaderService } from '@cpc-mpd/services';
import { MpdRoutes } from '@libs/cross-plat';

@Component({
  selector: 'mpd-unsubscribe-survey',
  templateUrl: './mpd-unsubscribe-survey.component.html',
  styleUrls: ['./mpd-unsubscribe-survey.component.scss'],
})
export class MpdUnsubscribeSurveyComponent {
  headerService = inject(HeaderService);
  public unsubscribeRoute = `/${this.headerService.currentRegion}/${MpdRoutes.Unsubscribed}`;
}
