<div class="heading">
  <span mat-dialog-title class="subtitle">
    Why your Professional Information?
  </span>
</div>
<div mat-dialog-content class="dialog-content">
  <p class="body">
    We use the professional information you provide to tailor communications to
    your profession and geographic location. For additional, relevant
    information, see our
    <!-- Maintain the weird formatting to avoid extra spaces within the anchor tag. -->
    <a [href]="privacyUrl" target="_blank" rel="noopener noreferrer"
      >Privacy Notice</a
    >.
  </p>
</div>
<div mat-dialog-actions class="center">
  <button mat-dialog-close mat-flat-button type="button" color="primary">
    Ok
  </button>
</div>
