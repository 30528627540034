<div
  class="container heading"
  fxLayout="row"
  fxLayoutGap="2%"
  fxLayoutAlign="space-between">
  <!--
    Put the ngIf directive on the div because if it is on the label
    inside the cpcStar directive, the parent is lost due to Angular
    having to dynamically create the element.
  -->
  <div class="group left" *ngIf="isRequired">
    <!-- i18n="@@marketing_title" -->
    <h3 i18n="@@marketing_title" class="lower bold" cpcStar>
      {{ title }}
    </h3>
  </div>
  <div class="group left" *ngIf="!isRequired">
    <label for="title" class="lower">{{ title }}</label>
  </div>
</div>
<div *ngIf="hasAny" class="left tiny-top">
  <span
    *ngIf="hasAll"
    class="action red"
    (click)="deselectAll()"
    i18n="'@@checkbox-grouping-deselectAll">
    Deselect All
  </span>
  <span
    *ngIf="!hasAll"
    class="action red"
    (click)="selectAll()"
    i18n="'@@checkbox-grouping-selectAll">
    Select All
  </span>
</div>
<div class="clear-float"></div>
<div class="needs-padding" *ngIf="isInvalid">
  <cpc-error-summary [message]="errorMessage"></cpc-error-summary>
</div>
<div class="needs-padding" *ngIf="isCategoryInvalid">
  <cpc-error-summary [message]="errorMessageCategory"></cpc-error-summary>
</div>
<div class="container boxes" fxLayout="row wrap" fxLayoutAlign="left">
  <div *ngFor="let item of items" fxFlex="50%" fxFlex.lt-md="100%">
    <mat-checkbox
      color="primary"
      [checked]="item.selected"
      [disabled]="disabled"
      (click)="toggleItem(item)">
      {{ item.name }}
    </mat-checkbox>
  </div>
</div>
