import { Component } from '@angular/core';
import { environment } from '@cpc-mpd/environment';

@Component({
  selector: 'mpd-contact-explain',
  templateUrl: './contact-explain.component.html',
  styleUrls: [
    // '../../../shared/styles/info-dialog.scss'
    './contact-explain.component.scss',
  ],
})
export class ContactExplainComponent {
  public get privacyUrl(): string {
    return environment.privacyUrl;
  }
}
