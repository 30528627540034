import { Component } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ErrorComponent } from '../base/error.component';

@Component({
  selector: 'cpc-error-primary-phone',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './error-primary-phone.component.html',
})
export class ErrorPrimaryPhoneComponent extends ErrorComponent {
  protected getError(errors: ValidationErrors): boolean {
    return !!errors && !!errors.required;
  }
}
