import { BehaviorSubject, Observable } from 'rxjs';
import { SalutationResponse, SelectableItem } from '@libs/cross-plat';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ApiHelperService, ErrorDialogService } from '@libs/ui-cdk';

@Injectable({
  providedIn: 'root',
})
export class SalutationService {
  private titles: BehaviorSubject<SelectableItem[]> | null = null;
  private retries = 0;

  constructor(
    private readonly apiHelper: ApiHelperService,
    private readonly http: HttpClient,
    private readonly errorService: ErrorDialogService
  ) {}

  public getSalutations(): Observable<SelectableItem[]> {
    if (!this.titles) {
      this.titles = new BehaviorSubject<SelectableItem[]>([]);
    } else {
      this.titles.next([]);
    }

    this.http
      .get<SalutationResponse>(this.apiHelper.getUrl('titles'))
      .subscribe(
        (data: SalutationResponse) => {
          const titles = data.titles || [];

          if (!!data && titles.length > 0) {
            this.titles?.next(titles);
          } else {
            this.retries = this.errorService.retryOrOpen(this.retries, () =>
              this.getSalutations()
            );
          }
        },
        () => {
          this.retries = this.errorService.retryOrOpen(this.retries, () =>
            this.getSalutations()
          );
        }
      );

    return this.titles;
  }
}
