import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'mpd-change-function',
  templateUrl: './change-function.component.html',
  styleUrls: [
    // '@libs/ui-cdk/src/scss/components/info-dialog.scss',
    '../../../../../../libs/ui-cdk/src/lib/scss/components/info-dialog.scss',
    './change-function.component.scss',
  ],
})
export class ChangeFunctionComponent {
  public current = '';

  constructor(
    private readonly dialogRef: MatDialogRef<ChangeFunctionComponent>,
    @Inject(MAT_DIALOG_DATA) data: { current: string }
  ) {
    this.current = data.current;
  }

  public cancelClick(): void {
    this.dialogRef.close(false);
  }

  public confirmClick(): void {
    this.dialogRef.close(true);
  }
}
