<div class="heading">
  <span mat-dialog-title class="subtitle">Why your Contact Information?</span>
</div>
<div mat-dialog-content class="dialog-content">
  <p class="body">
    We will use your Contact Information to provide updates, news, and topics of
    interest. For additional information, take a look at our
    <!-- Maintain the weird formatting to avoid extra spaces within the anchor tag. -->
    <a [href]="privacyUrl" target="_blank" rel="noopener noreferrer"
      >Privacy Notice</a
    >.
  </p>
</div>
<div mat-dialog-actions class="center">
  <button mat-dialog-close mat-flat-button type="button" color="primary">
    Ok
  </button>
</div>
