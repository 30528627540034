import { Component } from '@angular/core';
import { HeaderFooterService } from '@libs/cross-plat/helpers/header-footer.service';

@Component({
  selector: 'mpd-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  footerHtml: string;

  constructor(private headerFooterService: HeaderFooterService) {
    this.footerHtml = this.headerFooterService.headerFooterJson()
      ?.footer as string;
  }
}
