import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogRef, MatDialogModule } from '@angular/material/dialog';

@Component({
  selector: 'cpc-api-error',
  standalone: true,
  imports: [CommonModule, MatDialogModule],
  templateUrl: './api-error.component.html',
  styleUrls: ['./../../scss/components/info-dialog.scss'],
})
export class ApiErrorComponent {
  constructor(private readonly reference: MatDialogRef<ApiErrorComponent>) {}

  public close(): void {
    this.reference.close();
  }
}
