import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ErrorTypes } from '@libs/cross-plat/models';
import { ErrorComponent } from '../base/error.component';
import {
  ErrorEmailComponent,
  ErrorRequiredComponent,
  ErrorMaxLengthComponent,
  ErrorMinLengthComponent,
  ErrorPhoneNumberComponent,
  ErrorMaxComponent,
  ErrorMinComponent,
  ErrorPrimaryPhoneComponent,
} from '@libs/ui-cdk/components';
@Component({
  selector: 'cpc-error-collection',
  standalone: true,
  imports: [
    CommonModule,
    ErrorRequiredComponent,
    ErrorEmailComponent,
    ErrorMaxLengthComponent,
    ErrorMinLengthComponent,
    ErrorPhoneNumberComponent,
    ErrorMaxComponent,
    ErrorMinComponent,
    ErrorPrimaryPhoneComponent,
  ],
  templateUrl: './error-collection.component.html',
  styleUrls: ['./error-collection.component.scss'],
})
export class ErrorCollectionComponent extends ErrorComponent {
  public get types(): typeof ErrorTypes {
    return ErrorTypes;
  }

  protected getError(): boolean {
    // Nothing else to check here.
    return true;
  }

  public hasValidator(errorType: ErrorTypes): boolean {
    return this.source.errorTypes.indexOf(errorType) > -1;
  }
}
