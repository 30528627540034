export enum ErrorTypes {
  Email,
  MaxLength,
  MinLength,
  PhoneNumber,
  Required,
  Min,
  Max,
  Primary,
}
