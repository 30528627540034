<div
  class="container"
  fxLayout="row"
  fxLayout.lt-md="column"
  fxLayoutGap="2%"
  fxLayoutAlign="left">
  <div fxFlex="30%" fxFlex.lt-lg="40%" fxHide.lt-md>
    <img
      [src]="imageUrl"
      alt="Thank you for signing up to receive information from Gore Medical." />
  </div>
  <div fxFlex="70%" fxFlex.lt-lg="60%" fxFlex.lt-sm="100%">
    <div
      class="container"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutGap="2%"
      fxLayoutAlign="left">
      <div fxFlex="100%" class="title">
        <span>Ongoing innovation. Unwavering commitment.</span>
      </div>
    </div>
    <div
      class="container"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutGap="2%"
      fxLayoutAlign="left">
      <div fxFlex="100%">
        <p class="bold" *ngIf="hasResubscribed">
          Thank you for resubscribing to receive updates from the Gore Medical
          Products Division.
        </p>
        <p class="bold" *ngIf="!hasResubscribed">
          Thank you for signing up to receive updates from the Gore Medical
          Products Division.
        </p>
        <p>
          We are committed to ongoing innovation and providing healthcare
          professionals with purposeful solutions they can rely on to achieve
          long-term results for their patients.
        </p>
        <p>
          As the industry continues to transform, we're continuing to evolve,
          developing new ways to help healthcare professionals meet those
          challenges. It all starts with our unshakable drive to make a
          meaningful difference.
        </p>
      </div>
    </div>
    <div
      class="container more-info"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutGap="2%"
      fxLayoutAlign="left">
      <div fxFlex="100%">
        <p class="bold">
          The links below will give you more information about who we are and
          how we can help you.
        </p>

        <div
          class="container"
          fxLayout="row"
          fxLayout.lt-md="column"
          fxLayoutGap="2%"
          fxLayoutAlign="left">
          <div fxFlex="30%" fxFlex.lt-md="50%" fxFlex.lt-sm="100%">
            <iframe
              width="560"
              height="315"
              [src]="videoEmbedUrl"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen></iframe>
          </div>
          <div fxFlex="70%" fxFlex.lt-md="50%" fxFlex.lt-sm="100%">
            <p class="bold">Who we are.</p>
            <p>
              Learn about the values that have helped our company change the
              world.
            </p>
            <p>
              <!-- <a [href]="videoUrl" (click)="invokeAnalytics()" target="_blank" rel="noopener noreferrer">
                Watch video
              </a> -->
              <a
                [href]="videoUrl"
                (click)="clickVideo()"
                target="_blank"
                rel="noopener noreferrer">
                Watch video
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
