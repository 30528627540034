import { BehaviorSubject, Observable } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  /**
   * An Observable to denote whether or not the person who tried
   * to tried to update their preferences had most recently unsubscribed.
   */
  public get userIsUnsubscribed(): Observable<boolean> {
    return this.isUnsubscribed;
  }

  private isUnsubscribed = new BehaviorSubject<boolean>(false);

  /**
   * A function to save that the user who tried to access Manage
   * Preferences is already unsubscribed.
   */
  public userHasUnsubscribed(value: boolean): void {
    this.isUnsubscribed.next(value);
  }
}
