import { filter, map } from 'rxjs/operators';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Params } from '@angular/router';

import { QueryParams } from '@libs/cross-plat/models';
import { RouterService, TitleService } from '@libs/ui-cdk/services';
import { SubscriptionComponent } from './subscription.component';

@Component({ template: '' })
export abstract class MainPageComponent
  extends SubscriptionComponent
  implements OnInit
{
  protected federatedId = '';
  protected queryParams: QueryParams = { federatedId: '' };
  private batchId: string | null = null;
  private jobId: string | null = null;
  private listId: string | null = null;

  constructor(
    private readonly titleService: TitleService,
    private readonly routerService: RouterService,
    public readonly activatedRoute: ActivatedRoute
  ) {
    super();
  }

  public ngOnInit(): void {
    this.subs.push(
      this.activatedRoute.queryParams.subscribe((params: Params) => {
        this.batchId = (params['batchId'] as string)?.trim() || null;
        this.federatedId = (params['federatedId'] as string)?.trim() || '';
        this.jobId = (params['jobId'] as string)?.trim() || null;
        this.listId = (params['listId'] as string)?.trim() || null;

        this.queryParams = {
          federatedId: this.federatedId,
        };

        if (this.batchId) {
          this.queryParams.batchId = this.batchId;
        }
        if (this.jobId) {
          this.queryParams.jobId = this.jobId;
        }
        if (this.listId) {
          this.queryParams.listId = this.listId;
        }
      })
    );

    this.subs.push(
      this.routerService.events
        .pipe(
          filter((event: Event) => event instanceof NavigationEnd),
          map(() => {
            let child = this.activatedRoute;

            if (child) {
              // Traverse through nested route children.
              while (child.firstChild) {
                child = child.firstChild;
              }

              if (child.snapshot.data['title']) {
                return child.snapshot.data['title'] as string;
              }
            }

            return '';
          })
        )
        .subscribe((title: string) => {
          this.titleService.setTitle(title);
        })
    );
  }

  /**
   * A function to route to the given URL if no Federated ID was sent.
   * @param url The URL to route to.
   * @returns false if the federatedId doesn't exist, and the reroute will occur.
   */
  protected redirectIfNoFederatedId(url: string): boolean {
    if (!this.federatedId) {
      this.routerService.route([url]);
      return false;
    }

    return true;
  }
}
