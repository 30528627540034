import { CanActivateFn } from '@angular/router';
import { MPD_ROTERS_LIST } from '@libs/cross-plat';

export const checkRegionGuard: CanActivateFn = () => {
  const validRegions = ['en', 'ap', 'eu', 'la', 'me'];
  const validRoutes = MPD_ROTERS_LIST;
  const pathParts = window.location.pathname.split('/').filter(part => part);
  if (pathParts[0] && !validRegions.includes(pathParts[0])) {
    const regionDefault = 'en';
    const previewPath = window.location.pathname;
    if (validRoutes.includes(pathParts[0])) {
      pathParts.unshift(regionDefault);
    } else {
      pathParts[0] = regionDefault;
    }
    const newPath = `/${pathParts.join('/')}`;
    const newUrl = window.location.href.replace(previewPath, newPath);
    window.location.replace(newUrl);
    return false;
  }

  return true;
};
