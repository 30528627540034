<div class="error wrapper" *ngIf="isInvalid">
  <cpc-error-required
    *ngIf="hasValidator(types.Required)"
    [source]="source"></cpc-error-required>
  <cpc-error-email
    *ngIf="hasValidator(types.Email)"
    [source]="source"></cpc-error-email>
  <cpc-error-max-length
    *ngIf="hasValidator(types.MaxLength)"
    [source]="source"></cpc-error-max-length>
  <cpc-error-min-length
    *ngIf="hasValidator(types.MinLength)"
    [source]="source"></cpc-error-min-length>
  <cpc-error-phone-number
    *ngIf="hasValidator(types.PhoneNumber)"
    [source]="source"></cpc-error-phone-number>
  <cpc-error-max
    *ngIf="hasValidator(types.Max)"
    [source]="source"></cpc-error-max>
  <cpc-error-min
    *ngIf="hasValidator(types.Min)"
    [source]="source"></cpc-error-min>
  <cpc-error-primary-phone
    *ngIf="hasValidator(types.Primary)"
    [source]="source"></cpc-error-primary-phone>
</div>
