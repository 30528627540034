import { MainPageComponent } from '@libs/ui-cdk/components/base';
import { first } from 'rxjs/operators';

import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { environment } from '@cpc-mpd/environment';
import { RouterService, TitleService, UserService } from '@libs/ui-cdk';

@Component({
  selector: 'mpd-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss'],
})
export class ThankYouComponent extends MainPageComponent implements OnInit {
  public hasResubscribed = false;

  public get imageUrl(): string {
    return '../../../assets/mpd-group@2x.png';
  }

  public get videoEmbedUrl(): string | SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      environment.thankYouEmbedUrl
    );
  }

  public get videoUrl(): string {
    return environment.thankYouVideoUrl;
  }

  constructor(
    private readonly sanitizer: DomSanitizer,
    private readonly userService: UserService,
    titleService: TitleService,
    routerService: RouterService,
    activatedRoute: ActivatedRoute
  ) {
    super(titleService, routerService, activatedRoute);
  }

  public override ngOnInit(): void {
    this.invokeAnalytics();

    // Don't need to unsubscribe as first() will cause RxJs to
    // automatically unsubscribe when the first value arrives.
    // window.adobeDataLayer = window.adobeDataLayer || [];

    this.userService.userIsUnsubscribed
      .pipe(first())
      .subscribe((isUnsubscribed: boolean) => {
        this.hasResubscribed = isUnsubscribed;

        // Since we know the most recent event they've taken is subscribing,
        // reset to false in case the user goes back.
        this.userService.userHasUnsubscribed(false);
      });
  }

  public invokeAnalytics() {
    window.adobeDataLayer = window.adobeDataLayer || [];

    window.adobeDataLayer.push({
      event: 'pageLoad',
      pageInfo: {
        pageName: 'stayconnected:thankyou',
        pageNameCMS: 'stayconnected:thankyou',
        region: 'en_US',
        section: 'cpc:mpd:stayconnected:thankyou',
        assetType: 'ThankYouPage',
      },
    });
  }

  public clickVideo() {
    window.adobeDataLayer.push({
      event: 'click',
      eventInfo: {
        item: 'watchVideo',
      },
    });
  }
}
