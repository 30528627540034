import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute, Router } from '@angular/router';
import {
  RouterService,
  TitleService,
  UserService,
} from '@libs/ui-cdk/services';
import { MainPageComponent } from '../base/main-page.component';

/**
 * Displays the unsubscribe page. Any content inside the component is projected in the view.
 * String Input `resubscribeRoute` is used to redirect to another page, default value redirect to the root page.
 * Usage example:
 * @example
 * <cpc-unsubscribe resubscribeRoute="manage-preferences">
 *  <h2 i18n="@@psd_unsubscribed_message" *ngIf="isPsd">
 *     You have been unsubscribed fro  all marketing communications from Gore.
 *  </h2>
 * </cpc-unsubscribe>
 */
@Component({
  selector: 'cpc-unsubscribe',
  standalone: true,
  imports: [CommonModule, FlexLayoutModule, MatButtonModule],
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss'],
})
export class UnsubscribeComponent extends MainPageComponent implements OnInit {
  @Input() resubscribeRoute = ``;
  @Input() addI18n = false;
  constructor(
    private readonly router: Router,
    private readonly userService: UserService,
    titleService: TitleService,
    routerService: RouterService,
    activatedRoute: ActivatedRoute
  ) {
    super(titleService, routerService, activatedRoute);
  }

  public override ngOnInit(): void {
    super.ngOnInit();
  }

  public onResubscribe(): void {
    this.userService.userHasUnsubscribed(true);
    void this.router.navigate([this.resubscribeRoute]);
  }
}
