import { MainPageComponent } from '@libs/ui-cdk/components/base';

import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { RouterService, TitleService } from '@libs/ui-cdk/services';

@Component({ template: '' })
export abstract class ManagePreferencesBaseComponent extends MainPageComponent {
  protected hasReturned = false;
  protected inProgress = false;
  protected hasError = false;
  protected savedSuccessfully = false;

  public get showError(): boolean {
    return !this.inProgress && !this.hasReturned && this.hasError;
  }

  public get showUnsubscribe(): boolean {
    return !this.inProgress && !this.hasReturned && !this.hasError;
  }

  public get successful(): boolean {
    return !this.inProgress && this.hasReturned && this.savedSuccessfully;
  }

  public get unsuccessful(): boolean {
    return !this.inProgress && this.hasReturned && !this.savedSuccessfully;
  }

  constructor(
    titleService: TitleService,
    routerService: RouterService,
    activatedRoute: ActivatedRoute
  ) {
    super(titleService, routerService, activatedRoute);
  }
}
