import { BehaviorSubject, Observable } from 'rxjs';
import { SelectableItem, GetPreferences } from '@libs/cross-plat/models';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ApiHelperService } from './api-helper.service';
import { ErrorDialogService } from './error-dialog.service';

@Injectable({
  providedIn: 'root',
})
export class FacadePreferenceService {
  private default = {} as SelectableItem;
  private consent = new BehaviorSubject<SelectableItem>(this.default);
  private divisionPreferences = new BehaviorSubject<SelectableItem[]>([]);
  private marketingPreferences = new BehaviorSubject<SelectableItem[]>([]);
  private retries = 0;

  private isLoading = false;

  constructor(
    private readonly apiHelper: ApiHelperService,
    private readonly http: HttpClient,
    private readonly errorService: ErrorDialogService
  ) {}

  public getConsent(): Observable<SelectableItem> {
    if (!this.isLoading && this.consent.value === this.default) {
      this.loadData();
    }

    return this.consent;
  }

  public getDivisionPreferences(): Observable<SelectableItem[]> {
    if (!this.isLoading && this.divisionPreferences.value.length === 0) {
      this.loadData();
    }

    return this.divisionPreferences;
  }

  public getMarketingPreferences(): Observable<SelectableItem[]> {
    if (!this.isLoading && this.marketingPreferences.value.length === 0) {
      this.loadData();
    }

    return this.marketingPreferences;
  }

  private loadData(): void {
    this.isLoading = true;

    this.http
      .get<GetPreferences>(this.apiHelper.getUrl('preferences', true))
      .subscribe(
        (data: GetPreferences) => {
          if (data) {
            this.consent.next(data.consent);
            this.divisionPreferences.next(data.preferences);
            this.marketingPreferences.next(data.marketingPreferences);

            this.isLoading = false;
          } else {
            this.retries = this.errorService.retryOrOpen(this.retries, () =>
              this.loadData()
            );
          }
        },
        () => {
          this.retries = this.errorService.retryOrOpen(this.retries, () =>
            this.loadData()
          );
        }
      );
  }
}
