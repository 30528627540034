import { AbstractControl } from '@angular/forms';

import { SelectableItem } from '../models';

/**
 * A validator to conditionally determine if the given control is required or not.
 * @param related The related control whose value will determine if this control is required.
 * @param requiredValue The value that when the related control has that value makes this control required.
 * @returns null when no error exists; required error when invalid.
 */
export const REQUIRED_WHEN_VALIDATOR = (
  related: AbstractControl,
  // eslint-disable-next-line
  requiredValue: ((x: any) => boolean) | any
) => {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    let isSame = false;

    if (typeof requiredValue === 'function' && !!related.value) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      isSame = (requiredValue as (x: any) => boolean)(related.value);
    } else if (Array.isArray(requiredValue)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const val = related.value;

      if (Object.prototype.hasOwnProperty.call(val, 'id')) {
        isSame = requiredValue.indexOf((val as SelectableItem).id) > -1;
      } else {
        isSame = requiredValue.indexOf(val) > -1;
      }
    } else {
      isSame = related.value === requiredValue;
    }

    if (isSame && !control.value) {
      return { required: true };
    }

    return null;
  };
};
