import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[cpcStar]',
  standalone: true,
})
export class StarDirective implements OnInit {
  @Input()
  public removeSpace = false;

  private starSpan: HTMLSpanElement;

  constructor(private readonly element: ElementRef) {
    this.starSpan = document.createElement('span');
    this.starSpan.classList.add('required-star');

    const parent: HTMLElement | null = (
      this.element.nativeElement as HTMLElement
    ).parentElement;

    if (parent) {
      // Insert the span as the DOM is getting created.
      // This will allow the new span to get inserted at the same place
      // as it appears in the HTML files.
      parent.append(this.starSpan);
    }
  }

  public ngOnInit(): void {
    // Only set the text after we know whether or not we want the space.
    this.starSpan.innerText = this.removeSpace ? '*' : ' *';
  }
}
