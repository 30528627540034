import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PostProfileResponse } from '@libs/cross-plat';
import { ApiHelperService, ErrorDialogService } from '@libs/ui-cdk';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PreferencesService<T> {
  private preferences: BehaviorSubject<T> | null = null;
  // ---
  public latestConsentVersion: boolean | undefined = false;

  constructor(
    // private readonly form: FormDataService,
    private readonly apiHelper: ApiHelperService,
    private readonly http: HttpClient,
    private readonly errorService: ErrorDialogService
  ) {}

  // Tambien pasar apiURL
  public getPreferences(apiHelperUrl: string): Observable<T> {
    if (!this.preferences) {
      this.preferences = new BehaviorSubject<T>({} as T);
    } else {
      this.preferences.next({} as T);
    }

    // para linea de apihelperurl
    this.http.get<T>(apiHelperUrl).subscribe(
      (data: T) => {
        if (data) {
          this.preferences?.next(data);
        } else {
          this.errorService.open();
        }
      },
      () => this.errorService.open()
    );

    return this.preferences;
  }

  public save(
    registrationSource: string,
    data: object
  ): Observable<PostProfileResponse> {
    // let data = this.apiHelper.appendLocale(this.form.convertToProfile());
    // let data = this.apiHelper.appendLocale(profile);

    data = this.appendRegsitrationSource(data, registrationSource);

    return this.http.post<PostProfileResponse>(
      this.apiHelper.getUrl('profile', true),
      data,
      this.apiHelper.headers
    );
  }

  public update(
    federatedId: string,
    registrationSource: string,
    data: object
  ): Observable<void> {
    // let data = this.apiHelper.appendLocale(this.form.convertToProfile());

    data = this.appendRegsitrationSource(data, registrationSource);

    data = {
      ...{ federatedId },
      ...data,
      ...{ latestConsentVersion: this.latestConsentVersion },
    };

    return this.http.put<void>(this.apiHelper.getUrl('profile', true), data);
  }

  public appendRegsitrationSource<T>(data: T, registrationSource: string): T {
    return {
      ...data,
      registrationSource: registrationSource,
    };
  }
}
