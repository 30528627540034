import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'cpc-error-summary',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './error-summary.component.html',
  styleUrls: ['./error-summary.component.scss'],
})
export class ErrorSummaryComponent {
  @Input()
  public message = '';
}
