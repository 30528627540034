import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormHelperService {
  /**
   * A function to iterate through an entire Form Group and trigger validation on all Controls.
   * Will call recursively through children as needed.
   *
   * @param formGroup The Form Group to validate
   * @returns void
   */
  public validateAllFormFields(formGroup: UntypedFormGroup): void {
    formGroup.markAsTouched({ onlySelf: true });

    Object.keys(formGroup.controls).forEach((field: string) => {
      const control = formGroup.get(field);

      if (control) {
        control.markAsTouched({ onlySelf: true });
        if (control instanceof UntypedFormGroup) {
          this.validateAllFormFields(control);
          control.updateValueAndValidity({ onlySelf: true });
        }
      }
    });
  }

  /**
   * A function to validate a single Form Control.
   *
   * @param formControl The Form Control to validate
   * @returns void
   */
  public validateControl(formControl: UntypedFormControl): void {
    formControl.markAsTouched({ onlySelf: true });
    formControl.markAsDirty({ onlySelf: true });
  }
}
