import { BehaviorSubject, Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// import { GetUnsubscribeReasons } from '../models/get-unsubscribe-reasons';
import { SelectableItem, UnsubscribeResponse } from '@libs/cross-plat/models';
import { ApiHelperService } from './api-helper.service';

@Injectable({
  providedIn: 'root',
})
export class UnsubscribeService {
  private unsubscribeReasons: BehaviorSubject<SelectableItem[]> | null = null;

  constructor(
    private readonly apiHelper: ApiHelperService,
    private readonly http: HttpClient
  ) {}

  public getUnsubscribeReasons(): Observable<SelectableItem[]> {
    if (!this.unsubscribeReasons) {
      this.unsubscribeReasons = new BehaviorSubject<SelectableItem[]>([]);
    } else {
      this.unsubscribeReasons.next([]);
    }

    // this.http
    //   .get<GetUnsubscribeReasons>(this.apiHelper.getUrl('unsubscribe', true))
    //   .subscribe((data: GetUnsubscribeReasons) => {
    //     this.unsubscribeReasons?.next(data.reasons);
    //   });

    this.http
      .get<any>(this.apiHelper.getUrl('unsubscribe', true))
      .subscribe((data: any) => {
        this.unsubscribeReasons?.next(data.reasons);
      });

    return this.unsubscribeReasons;
  }

  public submit(data: UnsubscribeResponse): Observable<void> {
    data = this.apiHelper.appendLocale(data);

    return this.http.post<void>(
      this.apiHelper.getUrl('unsubscribe', true),
      data,
      this.apiHelper.headers
    );
  }
}
