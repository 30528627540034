import { BehaviorSubject, Observable } from 'rxjs';
import { SelectableItem, LanguagesResponse } from '@libs/cross-plat/models';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { HelperService } from '@libs/ui-cdk/services';
import { ApiHelperService } from './api-helper.service';
import { ErrorDialogService } from './error-dialog.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private languages: BehaviorSubject<SelectableItem[]> | null = null;
  private retries = 0;

  constructor(
    private readonly helperService: HelperService,
    private readonly apiHelper: ApiHelperService,
    private readonly http: HttpClient,
    private readonly errorService: ErrorDialogService
  ) {}

  public getLanguages(): Observable<SelectableItem[]> {
    if (!this.languages) {
      this.languages = new BehaviorSubject<SelectableItem[]>([]);

      this.http
        .get<LanguagesResponse>(this.apiHelper.getUrl('languages'))
        .subscribe(
          (response: LanguagesResponse) => {
            const languages = this.helperService.sort(
              response?.languages || []
            );

            if (languages.length) {
              this.languages?.next(languages);
            } else {
              this.retries = this.errorService.retryOrOpen(this.retries, () =>
                this.getLanguages()
              );
            }
          },
          () => {
            this.retries = this.errorService.retryOrOpen(this.retries, () =>
              this.getLanguages()
            );
          }
        );
    }

    return this.languages;
  }
}
